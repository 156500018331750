import themeHOC from 'core/components/theme';

import './index.styl';

export default function NativeListingSpecEventsStyles(Component) {
  return themeHOC(props => (
    <div className='native-listing-spec-events'>
      <style jsx global>{`
          .native-listing-spec-events
            border-bottom 1px solid ${props.theme.colors.divider}
            .ad_native_img
              &:before
                padding-top ${100 / (290 / 217) + '%'}
            .ad_native_sponsored_clone
              font 14px/20px ${props.theme.fonts.text}
              color ${props.theme.colors.primary}
        `}</style>
      <Component {...props} />
    </div>
  ));
}
