import { Route } from 'core/libs/router';
import { Helmet } from 'core/libs/helmet';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import LegalPage from 'core/components/LegalPage';
import RulesRecommenders from 'core/components/RulesRecommenders';

import Layout from 'site/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';

import {
  Fullscreen as DesktopFullscreen,
} from './components/Ads/desktop';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from './components/Ads/mobile';

import Main from './pages/main';
import Tag from 'site/pages/tag';
import Tags from 'site/pages/tags';
import Search from './pages/search';
import Topic from 'site/pages/topic';
import Comments from 'site/pages/comments';
import Rubric from 'site/pages/rubric';
import News from 'site/pages/news';
import Events from 'site/pages/events';
import About from 'site/pages/about';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import NotFound from './pages/not-found';
import Profile from './pages/profile';

export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <>
      <Helmet>
        <script src='https://ad.mail.ru/static/sync-loader.js' />
      </Helmet>
      <App {...config}>
        <Layout>
          <BaseRoutes
            authors={Authors}
            author={Author}
            tags={Tag}
            main={Main}
            topic={Topic}
            comments={Comments}
            about={About}
            search={Search}
            rubrics={Rubric}
            notFound={NotFound}
            profile={Profile}
            legal={LegalPage}
            rulesRecommenders={RulesRecommenders}
          >
            <Route
              exact
              path='/news'
              component={News}
            />
            <Route
              exact
              path='/events'
              component={Events}
            />
            <Route
              exact
              path='/label'
              component={Tags}
            />
          </BaseRoutes>
        </Layout>

        <Desktop>
          <DesktopFullscreen />
        </Desktop>

        <Mobile>
          <Rich />
          <MobileFullscreen />
        </Mobile>
      </App>
    </>
  );
}
