export const RELEASE_DATE = '2019-08-13';

// indents
export const SIDE_INDENT = 40;
export const SIDE_INDENT_MOBILE = 15;
export const VERTICAL_INDENT = 20;
export const VERTICAL_INDENT_MOBILE = 20;

// limits
export const RUBRICS_PAGE_LIMIT = 27;

// sizes
export const LAYOUT_MAX_WIDTH = 1280;
export const LAYOUT_MAX_WIDTH_MOBILE = 750;
export const CONTENT_AREA = 860;
export const RIGHT_COLUMN_WIDTH = 300;

export const rubrics = [
  {
    title: 'Медицина',
    slug: 'medicine',
  },
  {
    title: 'Астрономия',
    slug: 'astronomy',
  },
  {
    title: 'Биология',
    slug: 'biology',
  },
  {
    title: 'Гуманитарные науки',
    slug: 'humanitarian-science',
  },
  {
    title: 'Математика и CS',
    slug: 'mathematics',
  },
  {
    title: 'Науки о Земле',
    slug: 'earth-science',
  },
  {
    title: 'Сельское хозяйство',
    slug: 'agriculture',
  },
  {
    title: 'Технические науки',
    slug: 'engineering-science',
  },
  {
    title: 'Физика',
    slug: 'physics',
  },
  {
    title: 'Химия и науки о материалах',
    slug: 'chemistry-and-materials',
  },
];
