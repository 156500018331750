import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'alternative_headline',
  'published_at',
  'ad_label',
];

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});

function Card6(props) {
  const {
    content,
    theme,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    alternative_headline: altHeadline,
    published_at: published,
    ad_label: adLabel,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          color ${theme.colors.primary}
          font bold 30px/1 ${theme.fonts.display}
          transition color ${theme.animations.hover}

          .link:hover &
            color ${theme.colors.active1}

        .altheadline
          color ${theme.colors.primary}

      `}</style>
    </scope>
  );

  return (
    <div className={styles.card6}>
      <Link to={link} className={cx(styles.link, scoped.wrapClassNames('link'))}>
        <div className={styles.image}>
          <Image
            src={cover}
            previewSrc={previewCover}
            maxHeight={440}
            maxWidth={495}
          />
        </div>
        <div className={styles.content}>
          <div className={scoped.wrapClassNames('headline')}>
            {headline}
          </div>
          {altHeadline && (
            <div className={cx(styles.altheadline, scoped.wrapClassNames('altheadline'))}>
              {altHeadline}
            </div>
          )}
          <CardInfoLine
            className={styles.info}
            adLabel={adLabel}
            published={published}
          />
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

Card6.propTypes = {
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

const Card = cardHOC(Card6);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
