import color from 'color';
import cx from 'classnames';

import styles from './index.styl';

import MarkdownWrapper from 'core/components/MarkdownWrapper';

export default function tableMarkdown(block, parentProps) {
  const { theme } = parentProps;
  return (
    <div className={cx('table', styles.table)}>
      <style jsx>{`
        .table
          &:after
            background-image linear-gradient(to right,\
              ${color(theme.colors.layout).alpha(0).string()} 0%,\
              ${theme.colors.layout} 100%\
            )
          :global(td)
          :global(th)
            border-top 1px solid ${theme.colors.divider}
            color ${theme.colors.primary}
      `}</style>
      <MarkdownWrapper children={block.attributes.body} />
    </div>
  );
}
