import AdWrapper from 'core/components/Ad/AdWrapper';
import { Mobile, Desktop } from 'core/components/breakpoint';

import {
  Sponsored as DesktopSponsored,
} from 'site/components/Ads/desktop';

import {
  Sponsored as MobileSponsored,
} from 'site/components/Ads/mobile';

export default function Sponsored() {
  return (
    <AdWrapper top={20}>
      <Mobile>
        <MobileSponsored />
      </Mobile>
      <Desktop>
        <DesktopSponsored />
      </Desktop>
    </AdWrapper>
  );
}
