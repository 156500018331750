import get from 'lodash.get';
import Incut from 'core/components/Incut';
import SmartImage from 'core/components/SmartImage';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function opinion(block, parentProps) {
  const {
    theme,
  } = parentProps;

  const body = get(block, 'attributes.body');
  const name = get(block, 'attributes.author.attributes.name');
  const job = get(block, 'attributes.author.attributes.job_title');
  const image = get(block, 'attributes.author.attributes.avatar.attributes.versions.original');

  return (
    <div className={styles.incutWrapper}>
      <style jsx>{`
        .${styles.authorName}
          font-family ${theme.fonts.display}
      `}</style>
      <Incut>
        <MarkdownWrapper>
          {body}
        </MarkdownWrapper>
      </Incut>
      <div className={styles.author}>
        {image && (
          <div className={styles.authorImage}>
            <SmartImage
              src={image}
              width={70}
              height={70}
            />
          </div>
        )}
        <div className={styles.authorInfo}>
          <div className={styles.authorName}>
            <MarkdownWrapper>
              {name}
            </MarkdownWrapper>
          </div>
          <div className={styles.authorJob}>
            <MarkdownWrapper>
              {job}
            </MarkdownWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}
