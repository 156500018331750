import isSameDay from 'date-fns/isSameDay';
import { dateFormat } from 'core/utils/dates';

/**
 * Возвращает заголовок топика учитывая постраничную навигацию
 */
export function getTitle(headline, page, widgets) {
  let title = headline;

  const pageBrakes = (widgets || [])
    .filter(widget => widget.type === 'pageBreak');

  if (page > 1 && page <= pageBrakes.length + 1) {
    title = pageBrakes[page - 2].attributes.title;
  }

  return title;
}

export function formatEventDates(isoStart, isoEnd) {
  const startDate = new Date(isoStart);
  const endDate = new Date(isoEnd);

  const startPattern = 'dd MMMM HH:mm';
  const endPattern = isSameDay(startDate, endDate)
    ? 'HH:mm'
    : startPattern;

  return `${dateFormat(startDate, startPattern)}-${dateFormat(endDate, endPattern)}`;
}


/**
 * Возвращает дату в UTC
 * @param {string} dateString
 */
export function getUTCDate(dateString = Date.now()) {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  );
}

/**
 * Возвращает url указанной версии аватара.
 * @param {Object} [person={}] - объект, соответствующий модели person
 * @param {string} [version=original] - тип аватара, который нужно получить
 * @returns {Object} Объект аватара указанной версии или аватар-заглушка.
 */

export function getAvatar(person = {}, version = 'original') {
  const get = require('lodash.get');
  const path = [
    'attributes',
    'avatar',
    'attributes',
    'versions',
    version,
  ];

  const defaultAvatar = {
    rel_url: require('site/images/avatar.png'),
    width: 400,
    height: 400,
  };

  return get(person, path, defaultAvatar);
}
