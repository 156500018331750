import composeManager from 'core/resolver/composeManager';

import Topic from 'core/components/Topic';
import { Block, Section } from 'core/components/Grid';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';

import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import LightPageWrapper from 'site/components/LightPageWrapper';

import TopicHeader from 'site/pages/topic/TopicHeader';

const Manager = composeManager();

function TopicPageComments() {
  return (
    <Manager>
      <LightPageWrapper>
        <Topic>
          {content => {
            const {
              id,
              attributes: {
                link,
                is_comments_disabled: isCommentsDisabled,
              },
            } = content;

            return (
              <Section>
                <Block>
                  <TopicHeader
                    content={content}
                    showComments={false}
                    showMedia={false}
                    showAnnounce={false}
                  />
                  <Link to={link} >
                    <Button size='small'>Вернуться к статье</Button>
                  </Link>
                  <Comments xid={id} isCommentsDisabled={isCommentsDisabled} />
                </Block>
                <InnerSideColumn />
              </Section>
            );
          }}
        </Topic>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}

export default TopicPageComments;
