import Shapka from 'core/components/Shapka';

import styles from './index.styl';

export default function Shlyapa() {
  return (
    <div className={styles.shlyapa}>
      <Shapka />
    </div>
  );
}
