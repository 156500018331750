import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';

import bindPropsHOC from 'core/components/bindProps';

import Rubric from 'core/components/Rubric';
import { Block, Section } from 'core/components/Grid';

import { filterRequiredParams } from 'core/utils/api';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';

const ConfiguredRubric = bindPropsHOC({
  limit: RUBRICS_PAGE_LIMIT,
  include: 'image',
  topic_type: '-daily_photo,event',
  fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
})(Rubric);

const Manager = composeManager(
  ConfiguredRubric,
);

function RubricPage() {
  return (
    <Manager>
      <LightPageWrapper>
        <Section>
          <Block>
            <BindData>
              <ConfiguredRubric>
                {({ topics, rawRubric }) => (
                  <RubricTopics rubric={rawRubric.data.attributes.slug} topics={topics} />
                )}
              </ConfiguredRubric>
            </BindData>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}

export default RubricPage;
