import Feed from 'core/components/Feed';
import CardPhotoMain from 'site/cards/CardPhotoMain';

export default function DailyPhoto(props) {
  return (
    <Feed
      card={CardPhotoMain}
      limit={1}
      topic_type='daily_photo'
      interitemSpacing={0}
      {...props}
    />
  );
}
