import PropTypes from 'prop-types';

import composeManager from 'core/resolver/composeManager';

import SearchPage from 'core/components/SearchPage';
import { Block, Section } from 'core/components/Grid';
import withPageHocs from 'core/components/withPageHocs';

import resolve from 'core/resolver/resolve';

import { rawContentFetcher } from 'core/fetchers/searchPage';

import LightPageWrapper from 'site/components/LightPageWrapper';
import Boroda from 'site/components/Boroda';
import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import Card1 from 'site/cards/Card1';

import filters from './filters';

const Manager = composeManager();

Card1.requiredPayloadImports = ['image'];

/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search({ rawContent }) {
  return (
    <Manager>
      <LightPageWrapper>
        <Section>
          <Block>
            <SearchPage
              rawContent={rawContent}
              filters={filters}
              ad1={SuperFooter}
              ad2={Context}
              ad1Mobile={Listing1}
              ad2Mobile={ListingSpec}
              card={Card1}
              include='image'
            />
          </Block>
          <InnerSideColumn showBoesque={false} />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}

Search.propTypes = {
  rawContent: PropTypes.array,
};

const dataProvider = resolve({
  rawContent: rawContentFetcher({ card: Card1 }),
});

export default withPageHocs(dataProvider)(Search);
