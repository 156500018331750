import { Fragment } from 'react';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Redirect } from 'core/libs/router';

import compose from 'recompose/compose';
import withProps from 'recompose/withProps';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import { Block, Section } from 'core/components/Grid';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import { Indent } from 'site/components/Wrappers';
import Boroda from 'site/components/Boroda';
import Select, { Option } from 'site/components/Select';

import EventsTopics from './EventsTopics';

import cities from './cities';

import styles from './index.styl';

function onCityChange(history, event) {
  event.preventDefault();
  history.push(`/events?${queryString.stringify({
    city_slug: event.target.value,
  })}`);
}

function EventsPage(props) {
  const {
    citySlug,
    history,
    location,
    breakpoint,
  } = props;

  if (citySlug === 'all') {
    return <Redirect to='/events' />;
  }

  return (
    <Fragment>
      <LightPageWrapper>
        <Section>
          <Block>
            <div className={styles.header + ' ' + styles[breakpoint]}>
              <H1>События</H1>
              <Mobile><Indent top={10} /></Mobile>
              <Select
                defaultValue={citySlug ? citySlug : 'all'}
                onChange={onCityChange.bind(this, history)}
              >
                <Option value='all'>Все</Option>
                {cities.map(city => (
                  <Option
                    key={city.slug}
                    value={city.slug}
                  >
                    {city.title}
                  </Option>
                ))}
              </Select>
            </div>
            <EventsTopics location={location} citySlug={citySlug} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Fragment>
  );
}

EventsPage.propTypes = {
  citySlug: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default compose(
  withProps(({ location }) => ({
    citySlug: queryString.parse(location.search).city_slug,
  })),
  withBreakpoint,
)(EventsPage);
