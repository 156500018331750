import PropTypes from 'prop-types';

import { ImageWithText } from 'core/components/TopicContent/blocks/image';
import DotsSubstrate from 'site/components/DotsSubstrate';

import resolveRelationships from 'core/utils/relationships';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

export default function TopicHeaderImage({ content }) {
  const { image } = relationships(content);
  const { headline } = content.attributes;

  if (!image.versions.original) return null;

  const block = {
    id: '0',
    type: 'image',
    attributes: {
      ...image,
    },
  };

  return (
    <DotsSubstrate>
      <ImageWithText topicTitle={headline} block={block} />
    </DotsSubstrate>
  );
}

TopicHeaderImage.propTypes = {
  content: PropTypes.object,
};
