import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/components/Ad';
import AdBillboard from 'core/components/Ad/Billboard';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';

import nativeGlobal from './styles/global';

import { RIGHT_COLUMN_WIDTH } from 'site/constants';

import NativeFooterStyles from './styles/NativeFooter';
import Native3Styles from './styles/Native3';

export const Billboard = bindPropsHOC({
  name: 'Billboard',
  blockId: '435025668',
  options: {
    'p1': 'bvpvw',
    'p2': 'y',
    'pct': 'a',
  },
})(AdBillboard);

export const Fullscreen = bindPropsHOC({
  name: 'Fullscreen',
  blockId: '435025674',
  options: {
    'p1': 'bvpwg',
    'p2': 'emiu',
    'pct': 'a',
  },
})(Ad);

export const Ad240x400 = bindPropsHOC({
  name: '240x400',
  width: RIGHT_COLUMN_WIDTH,
  blockId: '435025472',
  options: {
    'p1': 'bvpvy',
    'p2': 'emhk',
    'pct': 'a',
  },
})(Ad);

export const Ad300x300 = bindPropsHOC({ // SelfPromo via adfox
  name: '300x300',
  width: RIGHT_COLUMN_WIDTH,
  blockId: '435025640',
  options: {
    'p1': 'bvpvx',
    'p2': 'ewtq',
    'pct': 'a',
  },
})(Ad);

export const Ad240x400Second = bindPropsHOC({
  name: '240x400_2nd',
  width: RIGHT_COLUMN_WIDTH,
  lazy: true,
  blockId: '435025482',
  options: {
    'p1': 'bvpvz',
    'p2': 'ewqs',
    'pct': 'a',
  },
})(Ad);

export const Ad240x200 = bindPropsHOC({
  name: '240x200',
  width: RIGHT_COLUMN_WIDTH,
  lazy: true,
  blockId: '435052470',
})(Ad);

export const ContextAd = bindPropsHOC({
  name: 'Context',
  lazy: true,
  blockId: '435025392',
  options: {
    'p1': 'bvpwf',
    'p2': 'feox',
    'pct': 'a',
  },
})(Ad);

export function Context({ count }) {
  return <ContextAd manualPuids={{ puid44: `context_item${count}` }} />;
}

Context.defaultProps = {
  count: 1,
};

Context.propTypes = {
  count: PropTypes.number,
};

export const Sponsored = compose(
  bindPropsHOC({
    name: 'Sponsored',
    blockId: '579328334',
  }),
  sponsoredStyles,
)(Ad);

export const SuperFooter = bindPropsHOC({
  name: '100%x240',
  lazy: true,
  blockId: '435025440',
  options: {
    'p1': 'bvpwa',
    'p2': 'fcuz',
    'pct': 'a',
  },
})(Ad);

export const Parallax = bindPropsHOC({
  name: 'Parallax',
  lazy: true,
  blockId: '533560963',
})(Ad);

export const AdCenter = bindPropsHOC({
  name: 'Center',
  lazy: true,
  blockId: '533560967',
})(Ad);

export const InRead = bindPropsHOC({
  name: 'inRead',
  blockId: '435025692',
  options: {
    'p1': 'bvpwf',
    'p2': 'feox',
    'pct': 'a',
  },
})(Ad);

export const Native1 = compose(
  bindPropsHOC({
    name: 'Native1',
    blockId: '435025718',
  }),
  nativeGlobal,
)(Ad);

export const Native2 = compose(
  bindPropsHOC({
    name: 'Native2',
    lazy: true,
    blockId: '435025722',
  }),
  nativeGlobal,
)(Ad);

export const Native3 = compose(
  bindPropsHOC({
    name: 'Native3',
    width: RIGHT_COLUMN_WIDTH,
    lazy: true,
    blockId: '533561011',
  }),
  nativeGlobal,
  Native3Styles,
)(Ad);

export const NativeFooter = compose(
  bindPropsHOC({
    name: 'NativeFooter',
    lazy: true,
    blockId: '533560975',
  }),
  nativeGlobal,
  NativeFooterStyles,
)(Ad);

export const InGallery = bindPropsHOC({
  name: 'InGallery',
  blockId: '579327448',
  disableAutoInit: true,
})(Ad);
