import PropTypes from 'prop-types';

import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Page from 'core/components/Page';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { Block, Section } from 'core/components/Grid';

import {
  SuperFooter,
  Parallax,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecMain,
} from 'site/components/Ads/mobile';
import FooterAd from 'site/components/Ads/FooterAd';

import { PageIndent, Indent } from 'site/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';
import SideColumn from 'core/components/SideColumn';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import Glavnoe from './Glavnoe';
import DiscussionClub from './DiscussionClub';
import MainEvents from './MainEvents';
import DailyPhoto from 'site/components/DailyPhoto';
import MainRubrics from './MainRubrics';
import MainPopular from './MainPopular';

const Manager = composeManager(
  Glavnoe,
  DiscussionClub,
  MainEvents,
  DailyPhoto,
);

function MainPage(props) {
  const {
    isMobile,
  } = props;

  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const vertical = isMobile ? 30 : 40;

  return (
    <Manager>
      <Page>
        <PageIndent>
          <Section>
            <Block>
              <AdWrapper bottom={vertical}>
                <BindData>
                  <Glavnoe />
                </BindData>
                <Indent top={vertical} />
                <Mobile>
                  <Listing1 />
                </Mobile>
                <BindData>
                  <DiscussionClub />
                </BindData>
                <Indent top={vertical} />
                <Desktop>
                  <SuperFooter />
                </Desktop>
                <BindData>
                  <MainEvents />
                </BindData>
                <Desktop><Indent top={vertical} /></Desktop>
              </AdWrapper>
            </Block>
            <InnerSideColumn />
          </Section>
          <Desktop>
            <AdWrapper
              left={-horisontal}
              right={-horisontal}
              bottom={vertical}
            >
              <Parallax />
            </AdWrapper>
          </Desktop>
          <Mobile>
            <AdWrapper top={vertical / 2} bottom={vertical}>
              <ListingSpecMain />
            </AdWrapper>
          </Mobile>
          <Section>
            <Block>
              <BindData>
                <DailyPhoto />
              </BindData>
            </Block>
            <SideColumn>
              <MainPopular />
            </SideColumn>
          </Section>
        </PageIndent>
        <MainRubrics />
        <FooterAd />
      </Page>
    </Manager>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPage);
