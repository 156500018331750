import themeHOC from 'core/components/theme';

import './index.styl';

export default function NativeFooterStyles(Component) {
  return themeHOC(props => (
    <div className='native-ad-footer'>
      <Component {...props} />
    </div>
  ));
}
