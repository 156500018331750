import React from 'react';
import Link from 'core/components/Link';
import Mjolnir from 'core/components/Mjolnir';
import H2 from 'core/components/H2';
import { Mobile, Desktop } from 'core/components/breakpoint';
import { Block, Section } from 'core/components/Grid';
import ContentBackground from 'core/components/ContentBackground';
import Scroller from 'core/components/Scroller';
import Button from 'core/components/Button';

import { filterRequiredParams } from 'core/utils/api';

import { Indent, PageIndent } from 'site/components/Wrappers';

import { rubrics } from 'site/constants';

import { Card1Type2 } from 'site/cards/Card1';
import Card4 from 'site/cards/Card4';
import { Card2Type4, Card2Type3 } from 'site/cards/Card2';

import styles from './index.styl';

const commonApiProps = {
  limit: 4,
  interitemSpacing: 20,
  include: 'image',
  fields: filterRequiredParams([Card1Type2, Card4, Card2Type4]),
  topic_type: '-event',
};

export default class MainRubrics extends React.Component {
  state = {
    rubric: rubrics[0].slug,
  };

  setRubric = rubric => () => {
    this.setState({ rubric });
  };

  render() {
    return (
      <ContentBackground>
        <Desktop>
          <Section>
            {rubrics.map(rubric => {
              return (
                <Block
                  key={rubric.slug}
                  desktop={6}
                  mobile={12}
                  className={styles.rubric}
                >
                  <i
                    className={styles.rubric__bg}
                    style={{ backgroundImage: `url("/static/images/rubrics/${rubric.slug}_rubric_back.jpg")` }}
                  />
                  <div className={styles.rubric__content}>
                    <H2 className={styles.rubric__title}>
                      <Link to={`/${rubric.slug}`} className={styles.rubric__title}>
                        {rubric.title}
                      </Link>
                    </H2>
                    <Indent bottom={20} />
                    <Mjolnir
                      {...commonApiProps}
                      rubric={rubric.slug}
                      heroCard={Card4}
                      card={Card2Type4}
                    />
                  </div>
                </Block>
              );
            })}
          </Section>
        </Desktop>
        <Mobile>
          <div style={{ padding: '20px 0' }}>
            <Scroller>
              {rubrics.map(rubric => {
                return (
                  <span key={rubric.slug} className={styles.rubric}>
                    <Button
                      size='small'
                      type='secondary'
                      onClick={this.setRubric(rubric.slug)}
                    >
                      {rubric.title}
                    </Button>
                  </span>
                );
              })}
            </Scroller>
            <PageIndent>
              <Mjolnir
                {...commonApiProps}
                heroCard={Card2Type3}
                card={Card1Type2}
                rubric={this.state.rubric}
              />
            </PageIndent>
          </div>
        </Mobile>
      </ContentBackground>
    );
  }
}
