import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import compose from 'recompose/compose';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Link from 'core/components/Link';
import { Indent } from 'site/components/Wrappers';

import styles from './index.styl';

function TagsContent(props) {
  const {
    tags,
    theme,
    breakpoint,
  } = props;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .tag
          color ${theme.colors.primary}
          transition background ${theme.animations.hover}
          :hover
            background ${theme.colors.active1}

        .letter
          font 16px/20px ${theme.fonts.nuance}
          color ${theme.colors.hint}
      `}</style>
    </scope>
  );

  let prevLetter = '#';

  return (
    <div className={cx(styles[breakpoint], styles.tags)}>
      <Indent top={25} />
      {tags.map(tag => {
        let letter = tag.attributes.title.charAt(0);
        if (!isNaN(letter)) {
          letter = '#';
        }
        const showLetter = letter !== prevLetter;
        prevLetter = letter;
        return (
          <Fragment key={tag.id}>
            {showLetter && (
              <div className={cx(styles.divider, scoped.wrapClassNames('divider'))}>
                <span className={cx(styles.letter, scoped.wrapClassNames('letter'))}>
                  {letter}
                </span>
              </div>
            )}
            <Link
              to={`/label/${tag.attributes.slug}`}
              className={cx(styles.tag, scoped.wrapClassNames('tag'))}
            >
              {tag.attributes.title}
            </Link>
          </Fragment>
        );
      })}
      <scoped.styles />
    </div>
  );
}

TagsContent.propTypes = {
  tags: PropTypes.array,
  theme: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default compose(
  withTheme,
  withBreakpoint,
)(TagsContent);
