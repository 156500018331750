import { Fragment } from 'react';
import get from 'lodash.get';

import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';

import bindPropsHOC from 'core/components/bindProps';

import TagTopics from 'core/components/TagTopics';
import H1 from 'core/components/H1';
import SocialShare from 'site/components/SocialShare';
import { Block, Section } from 'core/components/Grid';

import { filterRequiredParams } from 'core/utils/api';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import RubricTopics from 'site/components/RubricTopics';
import Boroda from 'site/components/Boroda';
import TopicHeaderImage from 'site/pages/topic/TopicHeaderImage';
import { Indent } from 'site/components/Wrappers';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card6 from 'site/cards/Card6';

const ConfiguredTagTopics = bindPropsHOC({
  limit: 12,
  include: 'image',
  fields: filterRequiredParams([Card1, Card2, Card6], 'fields'),
  titleComponent: ({ children, tag }) => ( // eslint-disable-line
    <Fragment>
      <H1>#{children}</H1>
      <SocialShare />
      {get(tag, 'relationships.image.data') && (
        <Fragment>
          <Indent top={25} />
          <TopicHeaderImage content={tag} />
        </Fragment>
      )}
    </Fragment>
  ),
  headerSpacing: 25,
})(TagTopics);

const Manager = composeManager(
  ConfiguredTagTopics,
);

function TagPage() {
  return (
    <Manager>
      <LightPageWrapper>
        <Section>
          <Block>
            <BindData>
              <ConfiguredTagTopics>
                {RubricTopics}
              </ConfiguredTagTopics>
            </BindData>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}

export default TagPage;
