import color from 'color';

const colors = {
  layout: '#fff', // Фон body
  content: '#fff', // Фон контентной области
  active1: '#78D9EA', // Доп. цвет Выделение
  get active2() {
    return this.active1;
  },
  primary: '#1F1F1F', // Основной цвет Текст
  secondary: '#7F7F7F', // Второстепенный цвет
  accent: '#78D9EA', // Ховеры, ссылки
  error: '#D53333', // Ошибки
  divider: '#F5F5F5', // Разделители
  get hint() { // Подписи к тексту, рубрики, доп. информация
    return color(this.secondary).alpha(0.7).string();
  },
  get placeholder() {
    return color(this.secondary).alpha(0.3).string();
  },
  get theme() {
    return this.active1;
  },
};

const button = {
  primary: {
    idle: {
      color: colors.layout,
    },

    hover: {
      color: colors.layout,
    },
  },
  secondary: {
    idle: {
      color: '#0E194B',
    },

    hover: {
      color: colors.active1,
      background: 'none',
    },
  },
};

const input = {
  basic: {
    idle: {
      border: `1px solid ${colors.placeholder}`,
    },

    hover: {
      border: `1px solid ${colors.placeholder}`,
    },

    focused: {
      border: `1px solid ${colors.active1}`,
    },
  },
};

const paginatron = {
  outerButtons: {
    hover: {
      background: button.secondary.hover.background,
    },
  },
};

export default {
  colors,
  controls: {
    button,
    input,
    paginatron,
  },
};
