import get from 'lodash.get';

import modelPropTypes, {
  readMoreAttributes,
} from 'core/utils/prop-types/model';

import Feed from 'core/components/Feed';
import ColorLine from 'site/components/ColorLine';
import { Card2Type1, Card2Type3 } from 'site/cards/Card2';

import styles from './index.styl';

export default function ReadMore(block) {
  const ids = get(block, 'attributes.topic_ids', []);

  if (!ids.length) return null;

  return (
    <div className={styles.readMore}>
      <Feed
        title='Читайте также'
        headerComponent={ColorLine}
        heroCard={Card2Type3}
        card={Card2Type1}
        ids={ids.join(',')}
        interitemSpacing={15}
      />
    </div>
  );
}

ReadMore.propTypes = {
  block: modelPropTypes(readMoreAttributes),
};
