import { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import isSameDay from 'date-fns/isSameDay';
import compose from 'recompose/compose';

import skip from 'core/resolver/skip';
import loader from 'core/resolver/loader';
import resolve from 'core/resolver/resolve';

import { dateFormat } from 'core/utils/dates';
import { getPage } from 'core/utils/url-helper';
import { filterRequiredParams } from 'core/utils/api';
import modelPropTypes, {
  topicAttributes,
} from 'core/utils/prop-types/model';

import { SimpleLoader } from 'core/components/Loader';
import ListPage from 'core/components/ListPage';
import { Desktop, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import H4 from 'core/components/H4';
import Text from 'core/components/Text';

import { Indent } from 'site/components/Wrappers';
import CardEventList from 'site/cards/CardEventList';

import {
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecEvents,
} from 'site/components/Ads/mobile';

import styles from './index.styl';

const fields = filterRequiredParams([CardEventList], 'fields');

const LIMIT = 10;

const format = date => {
  if (isSameDay(new Date(), new Date(date))) {
    return 'Сегодня';
  }

  return dateFormat(date, 'dd MMMM');
};

function EventsPage(props) {
  const {
    topics,
    meta,
    isDesktop,
  } = props;

  const spacing = isDesktop ? 35 : 20;

  let prevDate;

  return (
    <ListPage
      title={`События науки и техники ${new Date().getFullYear()}`}
      // description='TODO'
      rawData={topics}
      meta={meta}
      limit={LIMIT}
    >
      {({ content }) => {
        if (!content.length) {
          return (
            <Fragment>
              <Indent top={spacing} />
              <Text>Нет событий</Text>
            </Fragment>
          );
        }

        return (
          <Fragment>
            {content.map((topic, index) => {
              const started = get(topic, 'relationships.event.data.attributes.started_at');
              const sameDay = prevDate && started && isSameDay(new Date(prevDate), new Date(started));
              prevDate = started;

              return (
                <Fragment key={index}>
                  <Indent top={spacing} />
                  {!sameDay && (
                    <H4 className={styles.date}>
                      {format(started)}
                      <Indent top={20} />
                    </H4>
                  )}
                  <CardEventList content={topic} />
                  {index === 2 && (
                    <AdWrapper top={spacing}>
                      <Desktop><SuperFooter /></Desktop>
                      <Mobile><Listing1 /></Mobile>
                    </AdWrapper>
                  )}
                  {index === 6 && (
                    <AdWrapper top={spacing}>
                      <Desktop><Context /></Desktop>
                      <Mobile><ListingSpecEvents /></Mobile>
                    </AdWrapper>
                  )}
                </Fragment>
              );
            })}
          </Fragment>
        );
      }}
    </ListPage>
  );
}

EventsPage.propTypes = {
  topics: PropTypes.arrayOf(modelPropTypes(topicAttributes).isRequired),
  meta: PropTypes.shape({
    filtered_count: PropTypes.number,
  }),
  citySlug: PropTypes.string,
  isDesktop: PropTypes.bool,
};

const dataProvider = resolve({
  topics(props) {
    const {
      bebopApi,
      topics,
      location: { search },
      citySlug,
    } = props;

    /**
     * Отступ от начала списка данных
     * @type {number}
     */
    const offset = (getPage(search) - 1) * LIMIT;

    return topics || bebopApi
      .getTopics({
        limit: LIMIT,
        include: 'image,rubric,event',
        fields,
        sort: '-event.started_at',
        offset,
        topic_type: 'event',
        with_filtered_count: 1,
        plugin: JSON.stringify({
          event: {
            city_slug: citySlug,
          },
        }),
      })
      .catch(e => console.error(e));
  },
});

export default compose(
  loader(SimpleLoader),
  dataProvider,
  skip,
)(EventsPage);
