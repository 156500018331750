
export default [
  {
    slug: 'moscow',
    title: 'Москва',
  },
  {
    slug: 'st_petersburg',
    title: 'Санкт-Петербург',
  },
  {
    slug: 'novosibirsk',
    title: 'Новосибирск',
  },
  {
    slug: 'other',
    title: 'Прочие города',
  },
  {
    slug: 'online',
    title: 'Онлайн',
  },
];
