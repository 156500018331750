import PropTypes from 'prop-types';
import cx from 'classnames';
import withTheme from 'core/components/theme';
import ArrowDown from 'site/icons/ArrowDown';

import styles from './index.styl';

function Select(props) {
  const {
    className,
    theme,
    ...otherProps
  } = props;

  return (
    <span className={cx(styles.selectWrap, className)}>
      <style jsx>{`
        .select
          border ${theme.controls.input.basic.idle.border}
          font ${theme.controls.input.const.sizes.small.font}
          &:focus
            border ${theme.controls.input.basic.focused.border}
      `}</style>
      <select className={'select ' + styles.select} {...otherProps} />
      <ArrowDown width={9} className={styles.icon} />
    </span>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object,
};

export function Option(props) {
  return <option {...props} />;
}

export default withTheme(Select);
