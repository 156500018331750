import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import Recommender from 'core/components/Recommender';
import ColorLine from 'site/components/ColorLine';

import Card2 from 'site/cards/Card2';

function SiteRecommender({ isDesktop }) {
  const Renderer = isDesktop ? Feed : Drum;

  return (
    <Recommender
      blockId={isDesktop ? '39bb740e4a9e4b25acfd2ce50145034c' : '3427c0b5098f437dbda8d5a07c467871'}
      title='Читайте также'
      headerComponent={ColorLine}
      interitemSpacing={35}
      itemWidthMobile='180px'
      renderer={Renderer}
      card={Card2}
      columns={3}
      grid
      limit={6}
    />
  );
}

SiteRecommender.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(SiteRecommender);
