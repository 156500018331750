import deepmerge from 'deepmerge';

// import AnimatedLogo from 'site/components/AnimatedLogo';
import Logo from 'site/components/Logo';
import Burger from 'site/icons/Burger';
import Close from 'site/icons/Close';
import Comments from 'site/icons/Comments';
import DefaultAvatar from 'site/icons/DefaultAvatar';

import {
  VERTICAL_INDENT,
  SIDE_INDENT,
} from 'site/constants';

import './base.styl';
import fonts from './fonts';


const icons = {
  logo: Logo,
  burger: Burger,
  close: Close,
  comments: Comments,
  DefaultAvatar,
};

const layout = {
  sideColumnIndent: '60px',
  boxShadow: 'none',
  indents: {
    top: VERTICAL_INDENT,
    right: SIDE_INDENT,
    bottom: VERTICAL_INDENT,
    left: SIDE_INDENT,
  },
};

export default function baseTheme(atoms) {
  const texts = {
    lead: {
      font: `bold 24px/30px ${fonts.text}`,
    },

    body: {
      font: `20px/25px ${fonts.text}`,
    },

    title1: {
      font: `bold 48px/45px ${fonts.display}`,
    },

    title2: {
      font: `bold 30px/1 ${fonts.display}`,
    },

    title3: {
      font: `bold 24px/25px ${fonts.display}`,
    },

    title4: {
      font: `bold 20px/25px ${fonts.display}`,
    },

    title6: {
      font: `bold 16px/15px ${fonts.display}`,
    },

    incut: {
      font: `bold 18px/22px ${fonts.text}`,
    },
  };

  const link = {
    article: {
      idle: {
        decoration: 'none',
      },
      hover: {
        decoration: 'none',
      },
      active: {
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },

    secondary: {
      idle: {
        decoration: 'none',
        color: atoms.colors.secondary,
      },
      hover: {
        decoration: 'none',
        color: atoms.colors.hint,
      },
      active: {
        decoration: 'none',
      },
      visited: {
        color: false,
        decoration: false,
      },
    },
  };

  const vikontImage = {
    textAlign: 'left',
    background: atoms.colors.content,
    maxWidth: 680,
    maxHeight: 680,
    margin: '0',
    padding: '12px 20px',
    caption: {
      font: `14px/20px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    credits: {
      font: `14px/20px ${fonts.text}`,
      color: atoms.colors.accent,
    },
  };

  const shapkaLink = {
    font: `normal 12px/10px ${fonts.nuance}`,
    textTransform: 'uppercase',
    letterSpacing: '1px',
  };

  const shapka = {
    link: {
      ...shapkaLink,
      padding: '0 15px',
    },
    dropdown: {
      link: {
        ...shapkaLink,
        padding: '10px 15px',
      },
      background: atoms.colors.menu,
      boxShadow: 'none',
    },
    logo: {
      width: 240,
      height: 60,
    },
    height: 145,
    padding: '30px 40px 15px',
    background: 'none',
    backgroundOpened: 'none',

    labelNoAds: {
      fontWeight: 'bold',
      color: '#102A51',
      textTransform: 'none',
    },
  };

  const dropdown = {
    content: {
      background: atoms.colors.menu,
    },
  };

  const scooter = {
    social: {
      margin: '0 0 0 10px',
      size: 30,
      iconSize: 15,
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.active2,
        border: '1px solid transparent',
      },
      hover: {
        color: atoms.colors.active2,
        background: 'transparent',
        border: `1px solid ${atoms.colors.active2}`,
      },
    },
    padding: `0 ${SIDE_INDENT}px`,
    logo: {
      width: '240',
      height: '60',
    },
  };

  const button = {
    const: {
      sizes: {
        small: {
          font: `14px/15px ${fonts.nuance}`,
          height: '28px',
          padding: '5px 12px',
        },
        medium: {
          font: `16px/20px ${fonts.text}`,
          height: '38px',
        },
      },
      radius: '1px',
    },

    secondary: {
      idle: {
        color: atoms.colors.active1,
        border: '1px solid',
      },

      hover: {
        color: atoms.colors.primary,
        border: '1px solid',
      },

      active: {
        border: '1px solid',
      },

      disabled: {
        border: '1px solid',
      },
    },

    primary: {
      idle: {
        border: '1px solid transparent',
      },

      hover: {
        border: '1px solid transparent',
      },

      active: {
        border: '1px solid transparent',
      },

      disabled: {
        border: '1px solid transparent',
      },
    },
  };

  const bouesque = {
    const: {
      size: 'medium',
    },
  };

  const input = {
    const: {
      sizes: {
        small: {
          font: `14px/15px ${fonts.nuance}`,
          height: '28px',
        },
        medium: {
          font: `18px/1 ${fonts.nuance}`,
          height: '44px',
        },
      },
      radius: '0',
    },

    basic: {
      idle: {
        placeholderColor: atoms.colors.hint,
      },
    },
  };

  const gallery = {
    slide: {
      background: atoms.colors.content,
    },
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/images/macbookPreview-2x.png'),
      common: require('site/images/macbookPreview-1x.png'),
    },
  };

  const colors = {
    adsColor: '#102A51',
  };

  const personal = {
    headerButtonText: {
      color: '#11355A',
    },

    profileAvatar: {
      isCircular: true,
    },
  };

  const socializator = {
    fixedPosition: false,
    'buttonHeight': 28,
    'iconSize': 14,
    'borderRadius': 1,
    'borderWidth': 1,
    'textColor': '#A5A5A5',
  };

  const topicTopline = {
    font: `12px/1 ${fonts.nuance}`,
    color: atoms.colors.primary,
    textTransform: 'uppercase',
    rubric: {
      color: atoms.colors.active1,
      padding: '4px 0 2px 0',
      border: 'none',
      borderBottom: 'none',
      borderRadius: '0',
      textTransform: 'uppercase',
      decoration: 'none',
      hover: {
        color: atoms.colors.primary,
        borderBottom: 'none',
        background: 'transparent',
      },
    },
  };

  const topicContent = {
    widgetsMaxWidth: 680,
    widgetsWithMaxWidth: {
      'readMore': false,
      'tableMarkdown': true,
    },
    widgetsWithNegativeMobileIndent: {
      'incut': true,
      'opinion': true,
      'number': true,
      'readMore': false,
    },
  };

  const paginatron = {
    radius: button.const.radius,
    outerButtons: {
      innerBorder: `${button.secondary.idle.border} ${atoms.colors.primary}`,
      disabled: {
        color: atoms.colors.hint,
      },
      hover: {
        color: atoms.colors.active1,
      },
    },
  };

  const pages = {
    error: {
      subtitle: {
        name: 'Что-то пошло не так',
      },
    },
  };

  const login = {
    popup: {
      background: atoms.colors.primary,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    layout,
    controls: {
      link,
      shapka,
      scooter,
      gallery,
      vikontImage,
      button,
      input,
      bouesque,
      personal,
      socializator,
      dropdown,
      topicTopline,
      topicContent,
      paginatron,
      login,
    },
    ads,
    colors,
    pages,
  }, atoms);
}
