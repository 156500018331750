import PropTypes from 'prop-types';

import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import bindPropsHOC from 'core/components/bindProps';

import AuthorPage from 'core/components/AuthorPage';
import { Block, Section } from 'core/components/Grid';

import { filterRequiredParams } from 'core/utils/api';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';

const fields = filterRequiredParams([Card1, Card2], 'fields');

const ConfiguredAuthorPage = bindPropsHOC({
  limit: 15,
  include: 'rubric,image',
  columns: 3,
  grid: true,
  fields,
})(AuthorPage);

const Manager = composeManager(
  ConfiguredAuthorPage,
);

function Author({ isMobile }) {
  return (
    <Manager>
      <LightPageWrapper>
        <Section>
          <Block>
            <BindData>
              <ConfiguredAuthorPage
                card={isMobile ? Card1 : Card2}
              />
            </BindData>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}

Author.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Author);
