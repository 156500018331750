import color from 'color';

const colors = {
  layout: '#0C122A', // Фон body
  content: '#0C122A', // Фон контентной области
  active1: '#78D9EA', // Доп. цвет Выделение
  get active2() {
    return this.active1;
  },
  accent: '#78D9EA', // Ховеры, ссылки
  error: '#D53333', // Ошибки
  primary: '#fff', // Основной цвет Текст
  secondary: '#BFFFFF', // Второстепенный цвет
  input: 'transparent', // Второстепенный цвет
  divider: color('#BFFFFF').alpha(0.15).string(), // Разделители
  menu: '#134266',
  get hint() { // Подписи к тексту, рубрики, доп. информация
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.3).string();
  },
  get theme() {
    return this.active1;
  },
};

export default {
  colors,
};
