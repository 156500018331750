import Incut from 'core/components/Incut';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function incut(block) {
  return (
    <div className={styles.incutWrapper}>
      <Incut>
        <MarkdownWrapper>
          {block.attributes.body}
        </MarkdownWrapper>
      </Incut>
    </div>
  );
}
