import Ad from 'core/components/Ad/Amp';

const autoPad = '579327450';

export const TopBanner = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327454'
  />
);

export const Content1 = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327532'
  />
);

export const Content2 = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327534'
  />
);

export const Content3 = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327536'
  />
);

export const Content4 = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327538'
  />
);

export const Footer = () => (
  <Ad
    autoPad={autoPad}
    blockId='579327540'
  />
);
