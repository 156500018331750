import PropTypes from 'prop-types';
import ThemeSwitch from 'core/components/ThemeSwitch';
import GalleryWithAds from 'site/components/GalleryWithAds';


function TopicHeaderGallery({ content }) {
  const {
    photo_gallery: {
      data: photoGallery,
    },
  } = content.relationships;

  return photoGallery ? (
    <ThemeSwitch name='dark'>
      <GalleryWithAds
        photoGallery={photoGallery}
        hashNavigation
        infoUnderThemeName='light'
        adsToReload={[
          {
            name: 'Billboard',
            count: 4,
          },
          {
            name: '240x400',
            count: 4,
          },
          {
            name: 'TopBanner',
            count: 4,
          },
        ]}
      />
    </ThemeSwitch>
  ) : null;
}

TopicHeaderGallery.propTypes = {
  content: PropTypes.object,
};

export default TopicHeaderGallery;
