import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { formatEventDates } from 'site/utils';

import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';
import EventDetail from 'site/components/EventDetail';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric', 'event'];
const requiredPayloadFields = [
  'link',
  'headline',
  'announce',
  'ad_label',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: {},
  event: {},
});

function CardEventList(props) {
  const {
    content,
    breakpoint,
    theme,
    isDesktop,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    announce,
    ad_label: adLabel,
  } = content.attributes;

  const {
    image: {
      versions,
    },
    rubric,
    event,
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          display block
          color ${theme.colors.primary}
          font bold 20px/25px ${theme.fonts.display}
          transition color ${theme.animations.hover}

        .announce
          color ${theme.colors.primary}

        .link
          &:hover
            .headline
              color ${theme.colors.active1}

        .time
          font bold 13px/20px ${theme.fonts.nuance}
          color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(
      styles.cardEventList,
      styles[breakpoint],
    )}
    >
      <div className={styles.topline}>
        <div className={cx(styles.time, scoped.wrapClassNames('time'))}>
          {formatEventDates(event.started_at, event.ended_at)}
        </div>
        <CardInfoLine
          eventType={event.event_type}
          rubric={rubric}
          adLabel={adLabel}
          className={styles.toplineInfo}
        />
      </div>
      <Link to={link} className={cx(styles.link, scoped.wrapClassNames('link'))}>
        <div className={styles.body}>
          <div className={cx(styles.headline, scoped.wrapClassNames('headline'))} >
            {headline}
          </div>
          {announce && (
            <div className={cx(styles.announce, scoped.wrapClassNames('announce'))} >
              {announce}
            </div>
          )}
          <EventDetail event={event} />
        </div>
        <div className={styles.image}>
          <Image
            versions={versions}
            {...isDesktop
              ? { width: 230, height: 172 }
              : { aspectRatio: 290 / 217 }}
          />
        </div>
      </Link>
      <scoped.styles />
    </div>
  );
}

CardEventList.propTypes = {
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

const Card = cardHOC(CardEventList);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;
