import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';

import cardHOC from 'core/components/card';
import bindPropsHOC from 'core/components/bindProps';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import BeautyTime from 'site/components/BeautyTime';
import CardInfoLine from 'site/components/CardInfoLine';

import { SIDE_INDENT_MOBILE } from 'site/constants';

import types from './types';

import styles from './index.styl';

const requiredPayloadImports = [];

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'ad_label',
];

const relationships = resolveRelationships(['image', 'rubric', 'rubric_root'], {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function Card2(props) {
  const {
    content,
    breakpoint,
    theme,
    type,
    isDesktop,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    published_at: published,
    ad_label: adLabel,
  } = content.attributes;

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    imageWidthDesktop,
    imageWidthMobile,
    showBorder,
    negativeImageMobileIndent,
    thinText,
    showTimeBefore,
  } = types[type] || {};

  const imageWidth = isDesktop ? imageWidthDesktop : imageWidthMobile;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  return (
    <div className={cx(
      'card2',
      styles.card2,
      breakpoint,
      showBorder && 'border',
      thinText && 'thinText',
    )}
    >
      <style jsx>{`
        .border
          padding-bottom 15px
          border-bottom 1px solid ${theme.colors.divider}

        .headline
          color ${theme.colors.primary}
          transition color ${theme.animations.hover}
          font-family ${theme.fonts.display}
          :global(a:hover) &
            color ${theme.colors.active1}
          .thinText &
            font-weight normal

        .image
          .mobile &.negative
            margin-right -${SIDE_INDENT_MOBILE}px
            margin-left -${SIDE_INDENT_MOBILE}px
      `}
      </style>
      <Link
        to={link}
        className={styles.link}
        {...linkAttrs && {
          innerRef: node => {
            if (node) {
              linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
            }
          },
        }}
      >
        {showTimeBefore && <BeautyTime date={published} />}
        {(cover || remoteImage) && (imageWidth === 'auto' || imageWidth > 0) && (
          <div className={cx(styles.image, 'image', negativeImageMobileIndent && 'negative')}>
            <Image
              src={cover}
              previewSrc={previewCover}
              url={remoteImage}
              maxWidth={imageWidth === 'auto' ? undefined : imageWidth}
              aspectRatio={isDesktop ? 1.42 : 1.77}
            />
          </div>
        )}
        <div className={`${styles.headline} headline`}>
          {headline}
        </div>
      </Link>
      <CardInfoLine
        adLabel={adLabel}
        rubric={rubric}
        published={published}
        showTime={!showTimeBefore}
      />
    </div>
  );
}

Card2.propTypes = {
  type: PropTypes.oneOf([0, 1, 2, 3, 4]),
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  isDesktop: PropTypes.bool,
  theme: PropTypes.object,
};

Card2.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card2);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card2Type1 = bindPropsHOC({ type: 1 })(Card);
export const Card2Type2 = bindPropsHOC({ type: 2 })(Card);
export const Card2Type3 = bindPropsHOC({ type: 3 })(Card);
export const Card2Type4 = bindPropsHOC({ type: 4 })(Card);

export default Card;
