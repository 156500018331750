import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Scooter from 'core/components/Scooter';
import Link from 'core/components/Link';

export default class SiteScooter extends React.Component {
  static contextTypes = {
    bebopApi: PropTypes.object,
  };

  state = {
    dailyPhotoLink: null,
  };

  componentDidMount() {
    this.context.bebopApi
      .getTopics({ limit: 1, topic_type: 'daily_photo' })
      .then(responce => this.setAtomicState({
        'dailyPhotoLink': get(responce, 'data[0].attributes.link'),
      }));
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setAtomicState(...args) {
    if (!this.unmounted) {
      this.setState(...args);
    }
  }

  render() {
    const { dailyPhotoLink } = this.state;

    return (
      <Scooter
        additionalMenuItems={dailyPhotoLink && [{ content: 'Фото дня', link: dailyPhotoLink }]}
        additionalMenuItemsIndex={2}
        services={[
          { link: '/about', content: 'Редакция' },
          { link: '/about/#advertising', content: 'Размещение рекламы' },
          { link: '/about/#contacts', content: 'Научным учреждениям' },
          { link: '/exports/rss', content: 'RSS ' },
          { link: '/legal', content: 'Правовая информация' },
          { link: '/legal/terms-of-use', content: 'Условия использования' },
          { link: '/legal/privacy', content: 'Политика конфиденциальности' },
        ]}
        commercial={[
          'Все права защищены. Полное или частичное копирование материалов Сайта в коммерческих целях разрешено только с письменного разрешения владельца Сайта. В случае обнаружения нарушений, виновные лица могут быть привлечены к ответственности в соответствии с действующим законодательством Российской Федерации.',
          {
            body: <Link type='tertiary' to='/rules-recommenders'>На информационном ресурсе применяются рекомендательные технологии в соответствии с Правилами</Link>,
          },
        ]}
        contacts={{
          site: 'Indicator',
          age: 18,
          phone: {
            caption: 'Телефон редакции:',
            body: '+7 495 785-17-00',
          },
        }}
      />
    );
  }
}
