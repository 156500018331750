import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './index.styl';

export default function MaxWidthWrapper(props) {
  const { className, ...otherProps } = props;
  return <div className={cx(styles.maxWidthWrapper, className)} {...otherProps} />;
}

MaxWidthWrapper.propTypes = {
  className: PropTypes.string,
};
