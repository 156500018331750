import MarkdownWrapper from 'core/components/MarkdownWrapper';

import styles from './index.styl';

export default function number(block, parentProps) {
  const {
    theme,
  } = parentProps;

  const {
    value,
    unit,
    body,
  } = block.attributes;

  return (
    <div className={styles.incutWrapper}>
      <style jsx>{`
        .${styles.numberValue}
          font-family ${theme.fonts.nuance}
      `}</style>
      <div className={styles.number}>
        <div className={'numberValue ' + styles.numberValue}>
          {value}
          {unit && <small>{unit}</small>}
        </div>
        <div>
          <MarkdownWrapper>
            {body}
          </MarkdownWrapper>
        </div>
      </div>
    </div>
  );
}
