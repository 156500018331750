import Gallery from 'core/components/Gallery';

import { InGallery } from 'site/components/Ads/desktop';

export default function GalleryWithAds(props) {
  return (
    <Gallery
      injectAds={{
        desktop: {
          component: InGallery,
          count: 5,
        },
      }}
      {...props}
    />
  );
}
