import themeHOC from 'core/components/theme';

import './index.styl';

export default function Native3Styles(Component) {
  return themeHOC(props => (
    <div className='native-ad-3'>
      <Component {...props} />
    </div>
  ));
}
