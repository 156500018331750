import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import { formatEventDates } from 'site/utils';

import bindPropsHOC from 'core/components/bindProps';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'rubric', 'event'];

const requiredPayloadFields = [
  'link',
  'headline',
  'ad_label',
  'alternative_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  rubric: {},
  event: {},
});

function CardEventMain(props) {
  const {
    content,
    breakpoint,
    theme,
    type,
  } = props;

  if (!content) return null;

  const {
    link,
    headline,
    ad_label: adLabel,
    alternative_headline: altHeadline,
  } = content.attributes;

  const {
    showImage,
    showAnnounce,
  } = mapTypes[type] || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric,
    event,
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          display block
          color ${theme.colors.primary}
          font 18px/20px ${theme.fonts.display}
          transition color ${theme.animations.hover}

        .announce
          color ${theme.colors.primary}

        .link
          &:hover
            .headline
              color ${theme.colors.active1}

        .time
          font 13px/20px ${theme.fonts.nuance}
          color ${theme.colors.active1}
      `}</style>
    </scope>
  );

  const time = (
    <div className={cx(styles.time, scoped.wrapClassNames('time'))}>
      {formatEventDates(event.started_at, event.ended_at)}
    </div>
  );

  return (
    <div className={cx(
      styles.cardEventMain,
      styles['type' + type],
      styles[breakpoint],
    )}
    >
      {type === 0 && time}
      <div className={styles.content}>
        <Link to={link} className={cx(styles.link, scoped.wrapClassNames('link'))}>
          {cover && showImage && (
            <div className={styles.image}>
              <Image
                src={cover}
                previewSrc={previewCover}
                height={300}
              />
            </div>
          )}
          {type === 1 && time}
          <div className={cx(styles.headline, scoped.wrapClassNames('headline'))} >
            {headline}
          </div>
          {showAnnounce && (
            <div className={cx(styles.announce, scoped.wrapClassNames('announce'))} >
              {altHeadline}
            </div>
          )}
        </Link>

        <CardInfoLine
          eventType={event.event_type}
          rubric={rubric}
          adLabel={adLabel}
        />
      </div>
      <scoped.styles />
    </div>
  );
}

CardEventMain.propTypes = {
  type: PropTypes.oneOf([0, 1]),
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

CardEventMain.defaultProps = {
  type: 0,
};

const Card = cardHOC(CardEventMain);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const CardEventMainType1 = bindPropsHOC({ type: 1 })(Card);
export const CardEventMainType2 = bindPropsHOC({ type: 2 })(Card);

export default Card;
