import { TopicContentMaxWidth } from 'core/components/Wrappers';

import styles from './index.styl';

export default function DotsSubstrate(props) {
  return (
    <div className={styles.substrate}>
      <TopicContentMaxWidth>
        {props.children}
      </TopicContentMaxWidth>
    </div>
  );
}
