import PropTypes from 'prop-types';

import StickyPortal from 'core/components/StickyPortal';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';

import SideColumn from 'core/components/SideColumn';
import { Indent } from 'site/components/Wrappers';

import {
  Ad240x400,
  Ad240x200,
  Ad300x300,
  Ad240x400Second,
} from 'site/components/Ads/desktop';

import { VERTICAL_INDENT } from 'site/constants';

export default function InnerSideColumn(props) {
  const {
    show240x200,
    showBoesque,
    ...otherProps
  } = props;

  return (
    <SideColumn {...otherProps}>
      {showBoesque && (
        <Indent bottom={VERTICAL_INDENT}>
          <Bouesque />
        </Indent>
      )}
      <AdWrapper bottom={VERTICAL_INDENT}>
        <Ad240x400 stickyTemp={3} />
        <Ad300x300 />
        {show240x200 && <Ad240x200 />}
      </AdWrapper>
      <StickyPortal maxOffset={2400}>
        <AdWrapper bottom={VERTICAL_INDENT}>
          <Ad240x400Second />
        </AdWrapper>
      </StickyPortal>
    </SideColumn>
  );
}

InnerSideColumn.defaultProps = {
  showBoesque: true,
};

InnerSideColumn.propTypes = {
  showBoesque: PropTypes.bool,
  show240x200: PropTypes.bool,
};
