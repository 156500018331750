import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';

import { Helmet } from 'core/libs/helmet';

import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';
import dataProvider from 'core/resolver/data/topic';

import InfiniteTopic from 'core/components/InfiniteTopic';
import { Block, Section } from 'core/components/Grid';
import { Desktop, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { resolve } from 'core/utils/env';

import { Billboard } from 'site/components/Ads/desktop';
import { TopBanner } from 'site/components/Ads/amp';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import LightPageWrapper from 'site/components/LightPageWrapper';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import DailyPhoto from './DailyPhoto';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

const Manager = composeManager(
  InfiniteTopic,
);

function Render(props) {
  const { infinity, ...content } = props;

  const { attributes: { is_premium: isPremium } } = content;

  return (
    <Fragment>
      {infinity && (
        <AdWrapper left={40} right={40}>
          <Desktop>
            <Billboard
              lazy
              manualPuids={{ puid39: '1' }}
            />
          </Desktop>
          <Mobile>
            <TopBanner lazy />
          </Mobile>
        </AdWrapper>
      )}
      <LightPageWrapper>
        {isPremium && <TopicHeader content={content} />}
        <Section>
          <Block>
            {!isPremium && <TopicHeader content={content} />}
            <TopicContent />
            <TopicFooter content={content} infinity={infinity} />
          </Block>
          <InnerSideColumn show240x200 />
        </Section>
      </LightPageWrapper>
    </Fragment>
  );
}

function TopicPage({ content, isDesktop }) {
  const { attributes: { topic_type: topicType } } = content;

  if (topicType === 'daily_photo') {
    return (
      <Fragment>
        <DailyPhoto content={content} />
        <Boroda showAd />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Helmet>
        <script
          id='MathJax-script'
          async
          src='https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js'
        />
      </Helmet>
      <Manager>
        <BindData>
          <InfiniteTopic
            rcmBlockId={resolve({
              '*': '414cb0a3f2734dcfa038cd86cbdbba95',
              'production': isDesktop ? '1fdc845e9e5043f8b11b76a218afc9db' : '272b71e69be341298e20f1f39f898070',
            })}
            children={Render}
          />
        </BindData>
      </Manager>
      <Boroda hideMostDiscussed />
    </Fragment>
  );
}

TopicPage.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  isDesktop: PropTypes.bool,
};

Render.propTypes = {
  infinity: PropTypes.bool,
  attributes: topicAttributes.isRequired,
};

export default compose(withRouter, dataProvider, withBreakpoint)(TopicPage);
