import PTSansRegular from './ptsansregular/PTS55F-webfont.woff';
import PTSansRegular2 from './ptsansregular/PTS55F-webfont.woff2';
import PTSansBold from './ptsansbold/PTS75F-webfont.woff';
import PTSansBold2 from './ptsansbold/PTS75F-webfont.woff2';
import FuturaRegular from './futura/futurafuturisc-webfont.woff';
import FuturaRegular2 from './futura/futurafuturisc-webfont.woff2';
import FuturaBold from './futura/futurafuturisc-bold-webfont.woff';
import FuturaBold2 from './futura/futurafuturisc-bold-webfont.woff2';
import PTMonoRegular from './ptmono/PTM55F_W.woff';
import PTMonoRegular2 from './ptmono/PTM55F_W.woff2';
import PTMonoBold from './ptmono/PTM75F_W.woff';
import PTMonoBold2 from './ptmono/PTM75F_W.woff2';

const fonts = {
  text: 'PT Sans, PT Sans-fallback, sans-serif',
  display: 'Futura, sans-serif',
  nuance: 'PT Mono, sans-serif',
  faces: [
    {
      fontFamily: 'PT Sans',
      src: `url(${PTSansRegular2}) format('woff2'), url(${PTSansRegular}) format('woff')`,
    },
    {
      fontFamily: 'PT Sans',
      src: `url(${PTSansBold2}) format('woff2'), url(${PTSansBold}) format('woff')`,
      fontWeight: 'bold',
    },
    {
      fontFamily: 'Futura',
      src: `url(${FuturaRegular2}) format('woff2'), url(${FuturaRegular}) format('woff')`,
    },
    {
      fontFamily: 'Futura',
      src: `url(${FuturaBold2}) format('woff2'), url(${FuturaBold}) format('woff')`,
      fontWeight: 'bold',
    },
    {
      fontFamily: 'PT Mono',
      src: `url(${PTMonoRegular2}) format('woff2'), url(${PTMonoRegular}) format('woff')`,
    },
    {
      fontFamily: 'PT Mono',
      src: `url(${PTMonoBold2}) format('woff2'), url(${PTMonoBold}) format('woff')`,
      fontWeight: 'bold',
    },
    {
      fontFamily: 'PT Sans-fallback',
      sizeAdjust: '96.47%',
      ascentOverride: '110%',
      src: 'local("Arial")',
    },
  ],
};

export default fonts;
