import { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { dateFormat } from 'core/utils/dates';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import bindPropsHOC from 'core/components/bindProps';
import cardHOC from 'core/components/card';

import Link from 'core/components/Link';
import BeautyTime from 'site/components/BeautyTime';
import Image from 'core/components/Image';
import CardInfoLine from 'site/components/CardInfoLine';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = [];

const requiredPayloadFields = [
  'link',
  'headline',
  'published_at',
  'ad_label',
];

const relationships = resolveRelationships(['image', 'rubric', 'rubric_root'], {}, {
  image: {
    versions: {},
  },
  rubric: {},
});

function Card1(props) {
  const {
    content,
    breakpoint,
    theme,
    type,
  } = props;

  const {
    link,
    headline,
    published_at: published,
    ad_label: adLabel,
  } = content.attributes || {};

  const {
    remote_image: remoteImage,
    zero_pixel: zeroPixel,
  } = content.extra || {};

  useEffect(() => {
    if (zeroPixel) {
      const img = new Image();
      img.async = true;
      img.src = zeroPixel;
    }
  }, [zeroPixel]);

  if (!content) return null;

  const {
    showTime,
    showBeautyTime,
    showBorder,
    showImage,
  } = mapTypes[type] || {};

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
    rubric,
  } = relationships(content);

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .headline
          display block
          color ${theme.colors.primary}
          font 18px/20px ${theme.fonts.display}
          .image:hover + .content &
          :hover
            color ${theme.colors.active1}

        .time
          font 13px/1 ${theme.fonts.nuance}
          color ${theme.colors.active1}

        .border
          border-bottom 1px solid ${theme.colors.divider}
          padding-bottom 15px

      `}</style>
    </scope>
  );

  return (
    <div className={cx(
      styles.card1,
      styles['type' + type],
      breakpoint,
      showBorder && scoped.wrapClassNames('border'))}
    >
      {showBeautyTime && (
        <BeautyTime date={published} />
      )}
      <div className={styles.row}>
        {showImage && (cover || remoteImage) && (
          <Link to={link} className={cx(styles.image, scoped.wrapClassNames('image'))}>
            <Image
              src={cover}
              url={remoteImage}
              previewSrc={previewCover}
              width={100}
              height={60}
            />
          </Link>
        )}
        {showTime && (
          <div className={cx(styles.time, scoped.wrapClassNames('time'))}>
            {dateFormat(published, 'HH:mm')}
          </div>
        )}
        <div className={cx(styles.content, scoped.wrapClassNames('content'))}>
          <Link
            to={link}
            className={cx(styles.headline, scoped.wrapClassNames('headline'))}
          >
            {headline}
          </Link>
          <CardInfoLine
            adLabel={adLabel}
            rubric={rubric}
          />
        </div>
      </div>
      <scoped.styles />
    </div>
  );
}

Card1.propTypes = {
  type: PropTypes.oneOf([0, 1, 2, 3]),
  content: PropTypes.object,
  breakpoint: breakpointPropTypes(),
  theme: PropTypes.object,
};

Card1.defaultProps = {
  type: 0,
};

const Card = cardHOC(Card1);

Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card1Type1 = bindPropsHOC({ type: 1 })(Card);
export const Card1Type2 = bindPropsHOC({ type: 2 })(Card);
export const Card1Type3 = bindPropsHOC({ type: 3 })(Card);

export default Card;
