import GalleryWithAds from 'site/components/GalleryWithAds';
import DotsSubstrate from 'site/components/DotsSubstrate';

export default function photoGallery(block) {
  return (
    <DotsSubstrate>
      <GalleryWithAds photoGallery={block} />
    </DotsSubstrate>
  );
}
