import Feed from 'core/components/Feed';
import ColorLine from 'site/components/ColorLine';

import Card1 from 'site/cards/Card1';

export default function MainPopular() {
  return (
    <Feed
      title='Читайте также'
      headerComponent={ColorLine}
      card={Card1}
      limit={5}
      sort='-views_count'
      interitemSpacing={20}
    />
  );
}
