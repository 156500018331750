import compose from 'recompose/compose';
import nest from 'recompose/nest';

import bindPropsHOC from 'core/components/bindProps';

import Ad from 'core/components/Ad';

import sponsoredStyles from 'core/components/Ad/styles/sponsoredStyles';
import nativeGlobal from './styles/global';
import native3Styles from './styles/Native3';
import NativeListingSpecStyles from './styles/NativeListingSpec';
import NativeListingSpecMainStyles from './styles/NativeListingSpecMain';
import NativeListingSpecEventsStyles from './styles/NativeListingSpecEvents';

import { NegativeMobile } from 'site/components/Wrappers';

/**
 * У мобильных баннеров нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const MobileAd = bindPropsHOC({
  siteId: '579327450',
})(Ad);

const listingSpecProps = bindPropsHOC({
  name: 'Listing Spec (COM)',
  lazy: true,
  blockId: '579327522',
});

export const TopBanner = bindPropsHOC({
  name: 'TopBanner',
  blockId: '579327452',
})(nest(NegativeMobile, MobileAd));

export const Fullscreen = bindPropsHOC({
  name: 'Fullscreen',
  blockId: '579327514',
})(MobileAd);

export const Rich = bindPropsHOC({
  name: 'Rich',
  blockId: '579327516',
})(MobileAd);

export const Listing1 = bindPropsHOC({
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '579327458',
})(nest(NegativeMobile, MobileAd));

export const Listing2 = bindPropsHOC({
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '579327460',
})(nest(NegativeMobile, MobileAd));

export const Listing3 = bindPropsHOC({
  name: 'Listing3 (COM)',
  lazy: true,
  blockId: '579327462',
})(nest(NegativeMobile, MobileAd));

export const Listing4 = bindPropsHOC({
  name: 'Listing4 (COM)',
  lazy: true,
  blockId: '579327478',
})(nest(NegativeMobile, MobileAd));

export const ListingSpec = compose(
  listingSpecProps,
  nativeGlobal,
  NativeListingSpecStyles,
)(MobileAd);

export const ListingSpecMain = compose(
  listingSpecProps,
  nativeGlobal,
  NativeListingSpecMainStyles,
)(MobileAd);

export const ListingSpecEvents = compose(
  listingSpecProps,
  nativeGlobal,
  NativeListingSpecEventsStyles,
)(MobileAd);

export const Footer = bindPropsHOC({
  name: 'Footer (COM)',
  lazy: true,
  blockId: '579327512',
})(nest(NegativeMobile, MobileAd));

export const Content1 = bindPropsHOC({
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '579327486',
})(nest(NegativeMobile, MobileAd));

export const Content2 = bindPropsHOC({
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '579327498',
})(nest(NegativeMobile, MobileAd));

export const Content3 = bindPropsHOC({
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '579327508',
})(nest(NegativeMobile, MobileAd));

export const Content4 = bindPropsHOC({
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '579327510',
})(nest(NegativeMobile, MobileAd));

export const ContentSpec = compose(
  bindPropsHOC({
    name: 'Content Spec (COM)',
    lazy: true,
    blockId: '579327520',
  }),
  nativeGlobal,
  native3Styles,
)(nest(NegativeMobile, MobileAd));

export const InPage = bindPropsHOC({
  name: 'InPage',
  blockId: '579327650',
})(nest(NegativeMobile, MobileAd));

export const Sponsored = compose(
  bindPropsHOC({
    name: 'Sponsored (COM)',
    blockId: '579327518',
  }),
  sponsoredStyles,
)(MobileAd);
