import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import get from 'lodash.get';

import skip from 'core/resolver/skip';
import loader from 'core/resolver/loader';
import resolve from 'core/resolver/resolve';

import { Block, Section } from 'core/components/Grid';
import { SimpleLoader } from 'core/components/Loader';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';

import TagsContent from './TagsContent';

function TagsPage(props) {
  return (
    <Page
      title='Цифровая энциклопедия'
      description='Цифровая энциклопедия на Indicator.ru - последние новости об открытиях российских и зарубежных ученых, острые дискуссии об организации науки в России и взаимодействии науки и бизнеса, собственные рейтинги российских ученых, научных организаций и инновационных компаний.'
    >
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Цифровая энциклопедия</H1>
            <TagsContent tags={props.tags} />
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Page>
  );
}

TagsPage.propTypes = {
  tags: PropTypes.array,
};

const LIMIT = 100;

const firstDataProvider = resolve({
  firstTags(props) {
    const {
      bebopApi,
    } = props;

    return bebopApi
      .getTags({
        with_filtered_count: 1,
        sort: 'title',
        limit: LIMIT,
      })
      .catch(e => console.error(e));
  },
});

/**
 * Обходим ограничение апишки в 100 тегов на запрос
 */
const allDataProvider = resolve({
  tags(props) {
    const {
      firstTags,
      bebopApi,
    } = props;

    const count = get(firstTags, 'meta.filtered_count', 0);

    const iterations = Math.ceil(count / LIMIT);

    const payloads = [];

    for (let i = 1; i < iterations; i++) {
      payloads.push(
        bebopApi
          .getTags({
            offset: i * LIMIT,
            sort: 'title',
            limit: LIMIT,
          })
      );
    }

    return Promise.all(payloads)
      .then(data => {
        return data.reduce((stack, item) => {
          return {
            data: stack.data.concat(item.data),
          };
        }, firstTags);
      })
      .then(({ data }) => data);
  },
});

export default compose(
  loader(SimpleLoader),
  firstDataProvider,
  allDataProvider,
  skip,
)(TagsPage);
