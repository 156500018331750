import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import resolveRelationships from 'core/utils/relationships';
import { buildRubricUrl } from 'core/utils/url-helper';
import { dateFormat } from 'core/utils/dates';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import withTheme from 'core/components/theme';
import { PageIndent, Indent, NegativeMobile } from 'site/components/Wrappers';
import H1 from 'core/components/H1';
import H2 from 'core/components/H2';
import SmartImage from 'core/components/SmartImage';
import Text from 'core/components/Text';
import Link from 'core/components/Link';
import SocialShare from 'site/components/SocialShare';

import NextArrow from 'site/icons/NextArrow';
import PrevArrow from 'site/icons/PrevArrow';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['rubric', 'image'],
  {},
  {
    rubric: {},
    image: { versions: {} },
  },
);

const fields = 'link,published_at,headline,alternative_headline,announce';


class DailyPhoto extends React.Component {
  static contextTypes = {
    bebopApi: PropTypes.object,
  };

  state = {
    afterLink: null,
    beforeLink: null,
  };

  componentDidMount() {
    this.fetchTopic('after');
    this.fetchTopic('before');
  }

  componentDidUpdate(prevProps) {
    const { content: { attributes: { link: prevLink } } } = prevProps;
    const { content: { attributes: { link } } } = this.props;

    if (link !== prevLink) {
      this.fetchTopic('after');
      this.fetchTopic('before');
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setAtomicState(...args) {
    if (!this.unmounted) {
      this.setState(...args);
    }
  }

  fetchTopic = period => {
    const {
      attributes: {
        published_at: published,
      },
    } = this.props.content;

    this.context.bebopApi
      .getTopics({
        limit: 1,
        topic_type: 'daily_photo',
        [`published_${period}`]: published,
        sort: `${period === 'before' ? '-' : ''}published_at`,
        fields,
      })
      .then(responce => this.setAtomicState({
        [`${period}Link`]: get(responce, 'data[0].attributes.link'),
      }));
  };

  render() {
    const {
      content,
      breakpoint,
      isMobile,
      theme,
    } = this.props;

    const {
      id,
      attributes: {
        link,
        published_at: published,
        headline,
        alternative_headline: alternativeHeadline,
        announce,
      },
    } = content;

    const {
      rubric: {
        title: rubricTitle,
        slug: rubricSlug,
        root_slug: rubricRoot,
      },
      image: {
        versions: {
          original,
          thumbnail,
        },
        credits,
      },
    } = relationships(content);

    const {
      afterLink,
      beforeLink,
    } = this.state;

    return (
      <PageIndent>
        <style jsx>{`
          .topline
            font 12px/15px ${theme.fonts.nuance}
        `}</style>
        <div className={styles[breakpoint]}>
          <div className={`${styles.topline} topline`}>
            <span>Фото дня</span>
            <Link to={buildRubricUrl(rubricSlug, rubricRoot)}>
              {rubricTitle}
            </Link>
          </div>
          <NegativeMobile>
            <div className={styles.media}>
              <div className={styles.image}>
                <SmartImage
                  src={original}
                  previewSrc={thumbnail}
                  maxWidth={1200}
                  aspectRatio={16 / 9}
                />
              </div>
              {beforeLink && (
                <Link
                  className={styles.link + ' ' + styles.linkBefore}
                  to={beforeLink}
                >
                  <PrevArrow width={12} height={20} />
                </Link>
              )}
              {afterLink && (
                <Link
                  className={styles.link + ' ' + styles.linkAfter}
                  to={afterLink}
                >
                  <NextArrow width={12} height={20} />
                </Link>
              )}
            </div>
          </NegativeMobile>
          <div className={styles.body}>
            <div className={styles.date}>
              <H1 is='time' >{dateFormat(published, 'dd/MM')}</H1>
            </div>
            <div className={styles.text}>
              <H2 is='h1' className={styles.title}>{headline}</H2>
              <Indent top={isMobile ? 15 : 25} />
              <Text>{announce || alternativeHeadline}</Text>
              <Indent top={20} />
              <Text>Источник: {credits}</Text>
              <SocialShare
                xid={id}
                url={link}
              />
            </div>
            <time className={styles.year}>
              {new Date(published).getFullYear()}
            </time>
          </div>
        </div>
      </PageIndent>
    );
  }
}

DailyPhoto.propTypes = {
  content: modelPropTypes(topicAttributes).isRequired,
  breakpoint: breakpointPropTypes(),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(DailyPhoto));
