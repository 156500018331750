import deepmerge from 'core/libs/deepmerge';

import {
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import fonts from './fonts';


export default function baseMobileTheme(atoms) {
  const texts = {
    lead: {
      font: `bold 18px/25px ${fonts.text}`,
    },

    body: {
      font: `18px/25px ${fonts.text}`,
    },

    title1: {
      font: `bold 24px/25px ${fonts.display}`,
    },

    title2: {
      font: `bold 18px/22px ${fonts.display}`,
    },

    title3: {
      font: `bold 18px/22px ${fonts.display}`,
    },

    title4: {
      font: `bold 16px/12px ${fonts.display}`,
    },

    title6: {
      font: `bold 16px/15px ${fonts.display}`,
      color: atoms.colors.primary,
    },

    incut: {
      font: `bold 14px/20px ${fonts.text}`,
    },
  };

  const scooter = {
    padding: `0 ${SIDE_INDENT_MOBILE}px`,
    logo: {
      width: '145',
      height: '36',
    },
    social: {
      margin: 0,
      size: 30,
      iconSize: 15,
    },
  };

  const socializator = {
    'buttonHeight': 28,
    'iconSize': 14,
    'borderRadius': 1,
    'borderWidth': 1,
    'textColor': '#A5A5A5',
  };

  const shapka = {
    sticky: false,
    logo: {
      width: 145,
      height: 36,
    },
    padding: '0 15px 0 15px',
    background: atoms.colors.menu,
    stickyBackground: atoms.colors.menu,
    backgroundOpened: atoms.colors.menu,
  };

  const vikontImage = {
    padding: '10px 15px',
    maxWidth: 680,
    maxHeight: 680,
    caption: {
      font: `12px/15px ${fonts.text}`,
    },
    credits: {
      font: `12px/15px ${fonts.text}`,
    },
  };

  const layout = {
    indents: {
      top: VERTICAL_INDENT_MOBILE,
      right: SIDE_INDENT_MOBILE,
      bottom: VERTICAL_INDENT_MOBILE,
      left: SIDE_INDENT_MOBILE,
    },
  };

  return deepmerge({
    controls: {
      scooter,
      socializator,
      shapka,
      vikontImage,
    },
    layout,
    texts,
  }, atoms);
}

