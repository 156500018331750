import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import queryString from 'query-string';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';
import { getTitle, formatEventDates } from 'site/utils';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import H1 from 'core/components/H1';
import Lead from 'core/components/Lead';
import { Indent, MaxWidthWrapper } from 'site/components/Wrappers';
import EventDetail from 'site/components/EventDetail';
import SocialShare from 'site/components/SocialShare';

import Sponsored from 'site/components/Ads/Sponsored';

import TopicHeaderMedia from './TopicHeaderMedia';
import TopicTopline from 'core/components/TopicTopline';

import { RELEASE_DATE } from 'site/constants';

const relationships = resolveRelationships(
  ['rubric', 'image', 'content', 'event'],
  {},
  { image: { versions: {} }, event: {} },
);

function TopicHeader(props) {
  const {
    content,
    location: {
      search,
    },
    showMedia,
    showComments,
    showAnnounce,
    isMobile,
  } = props;

  const {
    id,
    attributes: {
      announce,
      headline,
      alternative_headline: alternativeHeadline,
      topic_type: topicType,
      link,
      published_at: publishedAt,
      is_comments_disabled: isCommentsDisabled,
    },
  } = content;

  const spacing = 20;

  const page = +queryString.parse(search).page || 1;

  /**
   * Показываем анонс только на 1 странице и только для новых метериалов
   */
  const needShowAnnounce = showAnnounce
    && page === 1
    && new Date(publishedAt).getTime() > new Date(RELEASE_DATE).getTime();

  const {
    content: {
      widgets,
    },
    event,
  } = relationships(content);

  const title = getTitle(headline, page, widgets);

  return (
    <Fragment>
      <Indent bottom={isMobile ? 16 : 20}>
        <TopicTopline />
      </Indent>
      <Indent bottom={24}>
        <H1>{title}</H1>
        <Sponsored />
        {topicType === 'event' && (
          <Fragment>
            <Indent top={spacing} />
            {formatEventDates(event.started_at, event.ended_at)}
            <EventDetail
              event={event}
              showButtons
              headline={headline}
              link={link}
              alternativeHeadline={alternativeHeadline}
            />
          </Fragment>
        )}
        {alternativeHeadline && (
          <Indent top={spacing}>
            <Lead>{alternativeHeadline}</Lead>
          </Indent>
        )}
        <SocialShare
          xid={id}
          url={link}
          isCommentsDisabled={isCommentsDisabled || !showComments}
        />
      </Indent>
      {page === 1 && showMedia && <TopicHeaderMedia content={content} />}
      {needShowAnnounce && (
        <MaxWidthWrapper style={{ margin: `${spacing}px auto` }}>
          <Lead>{announce}</Lead>
        </MaxWidthWrapper>
      )}
    </Fragment>
  );
}

TopicHeader.propTypes = {
  type: PropTypes.string,
  content: modelPropTypes(topicAttributes).isRequired,
  location: PropTypes.object.isRequired,
  showMedia: PropTypes.bool,
  showComments: PropTypes.bool,
  showAnnounce: PropTypes.bool,
  isMobile: PropTypes.bool,
};

TopicHeader.defaultProps = {
  showMedia: true,
  showComments: true,
  showAnnounce: true,
};

export default withBreakpoint(withRouter(TopicHeader));
