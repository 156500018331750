import PropTypes from 'prop-types';
import queryString from 'query-string';

import withTheme from 'core/components/theme';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { dateFormat } from 'core/utils/dates';
import { getUTCDate } from 'site/utils';

import Button from 'core/components/Button';
import Link from 'core/components/Link';

import MapPin from 'site/icons/MapPin';
import Kassa from 'site/icons/Kassa';
import Calendar from 'site/icons/Calendar';

import styles from './index.styl';

const GOOGLE_CALENDAR_URL = 'https://calendar.google.com/calendar/render';

const format = date => {
  return dateFormat(getUTCDate(date), "yyyyMMdd'T'HHmmss'Z'");
};

function EventDetail(props) {
  const {
    theme,
    event,
    showButtons,
    breakpoint,
    link,
    headline,
    alternativeHeadline,
  } = props;

  const calendarLink = GOOGLE_CALENDAR_URL + '?' + queryString.stringify({
    action: 'TEMPLATE',
    text: headline,
    dates: `${format(event.started_at)}/${format(event.ended_at)}`,
    details: `${alternativeHeadline}\nhttps://indicator.ru${link}`,
    location: event.address,
  });

  return (
    <div className={`list ${styles.list} ${styles[breakpoint]}`}>
      <style jsx>{`
        .list
          color ${theme.colors.hint}
          font 13px/15px ${theme.fonts.text}
      `}</style>
      {(event.address || event.venue) && (
        <div className={styles.item}>
          <MapPin height={13} />
          <span> {event.address}{event.venue && ` - ${event.venue}` }</span>
        </div>
      )}
      {event.cost && (
        <div className={styles.item}>
          <Kassa height={13} />
          <span> {event.cost}</span>
        </div>
      )}
      {showButtons && (
        <div className={styles.buttons + ' ' + styles.item} >
          {event.event_url && (
            <Link to={event.event_url} className={styles.regButton}>
              <Button>Зарегистрироваться</Button>
            </Link>
          )}
          <Link to={calendarLink}>
            <Button type='secondary'>
              <Calendar width={15} />
              &thinsp;
              Добавить в календарь
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

EventDetail.propTypes = {
  theme: PropTypes.object,
  event: PropTypes.object,
  showButtons: PropTypes.bool,
  breakpoint: PropTypes.string,
  /**
   * данные топика для формирования ссылки на гугл календарь
   */
  link: PropTypes.string,
  headline: PropTypes.string,
  alternativeHeadline: PropTypes.string,
};

export default withTheme(withBreakpoint(EventDetail));
