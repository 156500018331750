import PropTypes from 'prop-types';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Raker from 'core/components/Raker';

import ColorLine from 'site/components/ColorLine';

import CardEventMain, { CardEventMainType1 } from 'site/cards/CardEventMain';

function MainEvents({ isDesktop }) {
  return (
    <Raker
      title='События'
      headerComponent={ColorLine}
      list='events'
      sort='list'
      limit={5}
      sidebarGridSize={5}
      heroCard={CardEventMainType1}
      card={CardEventMain}
      interitemSpacing={isDesktop ? 40 : 20}
    />
  );
}

MainEvents.propTypes = {
  isDesktop: PropTypes.bool,
};

export default withBreakpoint(MainEvents);
