import { Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import Feed from 'core/components/Feed';
import Drum from 'core/components/Drum';
import { Block, Section } from 'core/components/Grid';
import Bouesque from 'core/components/Bouesque';
import SideColumn from 'core/components/SideColumn';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint } from 'core/components/breakpoint';

import { PageIndent } from 'site/components/Wrappers';
import ColorLine from 'site/components/ColorLine';
import Card2 from 'site/cards/Card2';
import FooterAd from 'site/components/Ads/FooterAd';
import { Ad240x400 } from 'site/components/Ads/desktop';

import { VERTICAL_INDENT } from 'site/constants';

function Boroda({ showAd, isDesktop, hideMostDiscussed }) {
  const Presenter = isDesktop ? Feed : Drum;
  return (
    <Fragment>
      <PageIndent>
        <Section>
          {!hideMostDiscussed && (
            <Block>
              <Presenter
                title='Обсуждаемое'
                headerComponent={ColorLine}
                sort='-comments_count'
                limit={6}
                card={Card2}
                interitemSpacing={35}
                columns={3}
                grid
                itemWidthMobile={180}
              />
            </Block>
          )}
          <SideColumn>
            {showAd && (
              <AdWrapper top={VERTICAL_INDENT}>
                <Bouesque />
                <Ad240x400 />
              </AdWrapper>
            )}
          </SideColumn>
        </Section>
      </PageIndent>
      <FooterAd />
    </Fragment>
  );
}

Boroda.propTypes = {
  isDesktop: PropTypes.bool,
  showAd: PropTypes.bool,
  hideMostDiscussed: PropTypes.bool,
};

export default compose(
  withBreakpoint,
)(Boroda);
