import H1 from 'core/components/H1';
import { Block, Section } from 'core/components/Grid';
import AuthorsPage from 'core/components/AuthorsPage';
import BindData from 'core/resolver/BindDataFromManager';
import composeManager from 'core/resolver/composeManager';

import LightPageWrapper from 'site/components/LightPageWrapper';
import InnerSideColumn from 'site/components/InnerSideColumn';
import Boroda from 'site/components/Boroda';
import { Indent } from 'site/components/Wrappers';

const Manager = composeManager(AuthorsPage);

function Authors() {
  return (
    <Manager>
      <LightPageWrapper>
        <Section>
          <Block>
            <H1>Авторы</H1>
            <Indent top={20} />
            <BindData>
              <AuthorsPage />
            </BindData>
          </Block>
          <InnerSideColumn />
        </Section>
      </LightPageWrapper>
      <Boroda />
    </Manager>
  );
}


export default Authors;
