import { Fragment } from 'react';
import { Mobile, Desktop } from 'core/components/breakpoint';
import { Context } from 'site/components/Ads/desktop';
import { Footer as FootBanner } from '../Ads/mobile';

export default function FooterAd() {
  return (
    <Fragment>
      <Desktop>
        <Context />
      </Desktop>
      <Mobile>
        <FootBanner />
      </Mobile>
    </Fragment>
  );
}
