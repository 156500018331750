import coreSnippet from 'core/components/TopicContent/blocks/snippet';
import DotsSubstrate from 'site/components/DotsSubstrate';

export default function snippet(...params) {
  return (
    <DotsSubstrate>
      {coreSnippet(...params)}
    </DotsSubstrate>
  );
}
