import coreImageWidget from 'core/components/TopicContent/blocks/image';
import DotsSubstrate from 'site/components/DotsSubstrate';

export default function image(...params) {
  return (
    <DotsSubstrate>
      {coreImageWidget(...params)}
    </DotsSubstrate>
  );
}
